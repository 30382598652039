<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <DietSidebar dietMainPage="guide"/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%; max-width: 1284px; margin:0 auto;">
          <div class="side-box" style="margin-bottom: 30px; position: relative; width: 100%;" >
            <div class="title">メディカルダイエット</div>
            <div class="LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=zFcyNv&liff_id=1657916768-pJgN5enw')">
              <div class="side-box">
                <img src="../../assets/SNS/line.png" style="height:50px">
                <div class="side-text-line">
                  <div style="font-size: 18px; text-align: center;">メディカルダイエット専用</div>
                  <div style="font-size: 18px; text-align: center;">お友達登録＋診療予約</div>
                </div>
              </div>
            </div>
          </div>

          

          <div class="separator">

            <div class="title_background">
              <div class="center-title" style="margin: 0 auto;">診療からお支払いまでの流れ</div>
            </div>

            <div class="text_background">
              <el-row type="flex" justify="center">
                <el-col :md="20" :lg="18" :xl="21">
                  <div class="content1">
                    <div class="side-text">
                      <div class="text_title_top">当医院ではオンライン診療とご来院どちらでもGLP-1の処方を行っており、下記の手順で受診いただけます。</div>
                      <div class="title1">オンラインでの診療をご希望の場合</div>
                      <div class="mark_online_count">
                        <div class="mark_online">ＬＩＮＥで問診に必要事項を入力しお申し込み下さい。<br>現在より二週間後まで予約が可能となります。</div>
                        <div class="mark_online">ご予約の日時になりましたら、お申し込み頂いたＬＩＮＥに電話でご連絡を差し上げます。</div>
                        <div class="mark_online">お電話口でカウンセリングの後、医師による診療を行います。</div>
                        <div class="mark_online">お申し込みいただいた住所もしくはご指定頂いた送付先宛てに、当院からお薬を発送します。</div>
                        <div class="mark_online">お薬をお受け取り下さい。</div>
                      </div>
                      <div class="content1">
                        <div class="text1">
                          お支払い方法
                          <br>​・銀行振込
                          <br>・各種クレジットカード
                        </div>
                      </div>


                      <div class="content1" style="height: 30px;"></div>


                    <div class="title1">外来での診療をご希望の場合</div>
                    <div class="mark_gairai_count">
                      <div class="mark_gairai"><div>ＬＩＮＥで問診に必要事項を入力しお申し込み下さい。<br>現在より二週間後まで予約が可能となります。</div></div>
                      <div class="mark_gairai">ご予約の日時に遅れないようにご来院ください。</div>
                      <div class="mark_gairai">お時間になりましたらカウンセリングの後、医師による診療を行います。</div>
                      <div class="mark_gairai">お薬をお渡しいたします。。</div>
                    </div>

                      <div class="content1">
                        <div class="text1">
                          お支払い方法
                          <br>​・クレジットカード（VISA、マスター）
                          <br>​・QRコード
                          <br>・現金
                        </div>
                    </div>
                    </div>

                      

                    


                  </div>
                </el-col>
              </el-row>
            </div>
                
          </div>
        </div>


      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <MDietSidebar/>
    <div class="side-box" style="margin-bottom: 30px;">
          <div class="title">メディカルダイエット</div>
      </div>

        

        <div class="separator">
          <div class="detail1" style="margin: 0 auto;">

            <div class="m_LINE_btn"  @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=LzuXdt&liff_id=1657916768-pJgN5enw')">
              <div class="side-box">
                <img src="../../assets/SNS/line.png" style="height:50px;margin-left: 20px">
                <div class="side-text-line">
                  <div style="font-size: 15px; text-align: center; width: 100%;">メディカルダイエット専用</div>
                  <div style="font-size: 15px; text-align: center; width: 100%;">お友達登録＋診療予約</div>
                </div>
              </div>
            </div>

            <div class="m_title_background">
              <div class="m-center-title" style="margin: 0 auto;">診療からお支払いまでの流れ</div>
            </div>

            <div class="text_background">

                  <div class="m_side-text">
                    <div class="m_text_title_top">当医院ではオンライン診療とご来院どちらでもGLP-1の処方を行っており、下記の手順で受診いただけます。</div>
                    <div class="title1">オンラインでの診療をご希望の場合</div>
                    <div class="mark_online_count">
                      <div class="mark_online">ＬＩＮＥで問診に必要事項を入力しお申し込み下さい。<br>現在より二週間後まで予約が可能となります。</div>
                      <div class="mark_online">ご予約の日時になりましたら、お申し込み頂いたＬＩＮＥに電話でご連絡を差し上げます。</div>
                      <div class="mark_online">お電話口でカウンセリングの後、医師による診療を行います。</div>
                      <div class="mark_online">お申し込みいただいた住所もしくはご指定頂いた送付先宛てに、当院からお薬を発送します。</div>
                      <div class="mark_online">お薬をお受け取り下さい。</div>
                    </div>

                      <div class="content1">
                        <div class="text1">
                          お支払い方法
                          <br>​・銀行振込
                          <br>・各種クレジットカード
                        </div>
                    </div>

                    <div class="content1" style="height: 60px;"></div>


                    <div class="title1">外来での診療をご希望の場合</div>
                    <div class="mark_gairai_count">
                      <div class="mark_gairai"><div>ＬＩＮＥで問診に必要事項を入力しお申し込み下さい。<br>現在より二週間後まで予約が可能となります。</div></div>
                      <div class="mark_gairai">ご予約の日時に遅れないようにご来院ください。</div>
                      <div class="mark_gairai">お時間になりましたらカウンセリングの後、医師による診療を行います。</div>
                      <div class="mark_gairai">お薬をお渡しいたします。。</div>
                    </div>

                      <div class="content1">
                        <div class="text1">
                          お支払い方法
                          <br>​・クレジットカード（VISA、マスター）
                          <br>​・QRコード
                          <br>・現金
                        </div>
                    </div>
                  </div>



            </div>
              
          </div>
        </div>



    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import DietSidebar from "@/components/DietSidebar";
import MDietSidebar from "../../components/m/MDietSidebar";
export default {
  name: "Guide",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar,MDietSidebar},
  methods:{
    goHref(path) {
      window.location.href = path;
    },
  }
}
</script>

<style scoped>

/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 30px;
}
.title1 {
  color: #aa7b22;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 40px;
}
.text1 {
  color: #0a4a6e;
  font-size: 18px;
  line-height: 1.5;
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
  height:5px;
  border-top-color: #CBB8B2;
  border-top-width: 2px;
  border-top-style: solid;
  margin: 0 auto;
  width: 90%;
}

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #CBB8B2;
  border-bottom: 2px solid #CBB8B2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #CBB8B2;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.center-title{
  color: #FFFFFF;
  font-size: 50px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.m-center-title{
  color: #FFFFFF;
  font-size: 25px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  position: absolute;
  right: 0px;
  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  cursor: pointer;
  transition: 150ms;
}

.LINE_btn:hover{
  background-color: #ffffff;
  color: #20ce88;
  box-shadow: 5px 5px 10px #42859b66,
  3px 3px 0px #20ce881b;
}
.m_LINE_btn{
  background-color: #20ce88;
  color: #FFFFFF;
  border-radius: 8px;
  width:80%;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}
.m_LINE_btn:active{
  background-color: #ffffff;
  color: #20ce88;
  border-radius: 8px;
  width:350px;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}

.banner_slogan{

  text-align: center;
  color: #326686;
  text-shadow: 1px 2px 0px #e9d5ce6b,
    0px 2px 3px #326686c4
    ;
  width: 100%;
}

.slogan_small{
  font-size: 15px;
  margin-top: 5px;
}

.slogan_big{
  font-size: 18px;
  margin-top: 15px;
}

.slogan_big_l{
  font-size: 30px;
  margin-top: 15px;
}

.slogan_large{
  font-size: 50px;  
}

.m_slogan_small{
  font-size: 5px;
  margin-top: 5px;
}

.m_slogan_big{
  font-size: 10px;
  margin-top: 5px;
}

.m_slogan_big_l{
  font-size: 12px;
  margin-top: 5px;
}

.m_slogan_large{
  font-size: 20px;  
}


.font_border{
  text-align: center;
  color: #326686;
  text-shadow: 
    0px 1px 4px #f5efe3,
    0px -1px 4px #f5efe3,
    1px 0px 4px #f5efe3,
    -1px 0px 4px #f5efe3;
  width: 100%;
}

.img_banner{
  position: relative;
  height: auto;
}


.bg_blue{
  background-color: #326686;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ryoukin_hint{
  color: #F71B1B;
  font-size: 16px;
  text-align: right;
  padding-right: 50px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }
    
    .separator {
      color: #CBB8B2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

.side-img{
height: fit-content;
width:fit-content;
vertical-align:middle;
margin-left: 30px;
margin-right: 30px;
}

.side-text{
height: fit-content;
margin-left: 50px;
margin-right: 50px;
margin-bottom: auto;
}
.m_side-text{
height: fit-content;
margin-left: 7%;
margin-right: 7%;
width: 86%;
margin-bottom: auto;
}

.side-text-line{
  height: fit-content;
}



.side-box{
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
  height: fit-content;
}

.side-line-highlight{
  background-color: #CBB8B2;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.title_background{
  background-color: #b7bfc4;
  width: 100%;
  height:150px;
  display: flex;
  align-items: center;
}

.m_title_background{
  background-color: #b7bfc4;
  width: 100%;
  height:75px;
  display: flex;
  align-items: center;
}

.text_background{
  width: 100%;
  height:fit-content;
  background-color: #ebe5e2;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 5px;

}

.text_title_top{
  font-size: 32px;
  color: #0a4a6e;
  
}

.m_text_title_top{
  font-size: 22px;
  color: #0a4a6e;
  
}

.mark_online_count{
  counter-reset: online;
}

.mark_online{
  color: #0a4a6e;
  font-size: 18px;
  line-height: 1.5;
  display: flex;
}

.mark_online::before{
  counter-increment: online;
  content: counter(online) ".　";
}

.mark_gairai_count{
  counter-reset: gairai;
}

.mark_gairai{
  color: #0a4a6e;
  font-size: 18px;
  line-height: 1.5;
  display: flex;
}

.mark_gairai::before{
  counter-increment: gairai;
  content: counter(gairai) ".　";
}

</style>
